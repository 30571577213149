import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

import shipmentAdapter from '../adapters/shipment'
import {
  shipmentChargeAdapter,
  shipmentChargesAdapter,
} from '../adapters/shipmentCharges'
import { shipmentChargeTypesAdapter } from '../adapters/shipmentChargeTypes'
import type {
  CancelChargeRequestPayload,
  CreateChargeRequestPayload,
  RejectChargeRequestPayload,
  ShipmentChargeAction,
  UploadChargeAttachmentResponsePayload,
  UpdateAppointmentPayload,
  Shipment,
  ShipmentTimelineEntryRaw,
} from '../types'

export const getShipmentDetails = async (
  uuid: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<Shipment>(`/shipments/${uuid}`, config)
  return shipmentAdapter(data)
}

export const getShipmentChargesURL = (shipmentUUID: string) =>
  `/shipments/${shipmentUUID}/charges`

export const getShipmentCharges = async (
  shipmentUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    getShipmentChargesURL(shipmentUUID),
    config
  )

  return shipmentChargesAdapter(data)
}

export const createShipmentCharge = async (
  shipmentUUID: string,
  payload: CreateChargeRequestPayload
) => {
  const { data } = await httpClient.post(
    getShipmentChargesURL(shipmentUUID),
    payload
  )

  return shipmentChargeAdapter(data)
}

export const getUploadChargeAttachmentURL = (chargeUUID: string) =>
  `/charges/${chargeUUID}/upload-file`

export const uploadChargeAttachment = async (
  chargeUUID: string,
  file: File
): Promise<UploadChargeAttachmentResponsePayload> => {
  const formData = new FormData()

  formData.append('attachments', file)

  const { data } = await httpClient.post(
    getUploadChargeAttachmentURL(chargeUUID),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return data
}

export const getShipmentChargeActionURL = (
  shipmentUUID: string,
  chargeUUID: string,
  action: ShipmentChargeAction
) => `${getShipmentChargesURL(shipmentUUID)}/${chargeUUID}/${action}`

export const approveShipmentCharge = async (
  shipmentUUID: string,
  chargeUUID: string
) => {
  const { data } = await httpClient.patch(
    getShipmentChargeActionURL(shipmentUUID, chargeUUID, 'approve')
  )

  return shipmentChargeAdapter(data)
}

export const rejectShipmentCharge = async (
  shipmentUUID: string,
  chargeUUID: string,
  payload: RejectChargeRequestPayload
) => {
  const { data } = await httpClient.patch(
    getShipmentChargeActionURL(shipmentUUID, chargeUUID, 'reject'),
    payload
  )

  return shipmentChargeAdapter(data)
}

export const cancelShipmentCharge = async (
  shipmentUUID: string,
  chargeUUID: string,
  payload: CancelChargeRequestPayload
) => {
  const { data } = await httpClient.patch(
    getShipmentChargeActionURL(shipmentUUID, chargeUUID, 'cancel'),
    payload
  )

  return shipmentChargeAdapter(data)
}

export const getAccountingSummaryURL = (shipmentUUID: string) =>
  `/shipments/${shipmentUUID}/accounting-summary`

export const getAccountingSummary = async (
  shipmentUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    getAccountingSummaryURL(shipmentUUID),
    config
  )

  return data
}

export const getRateconPDF = async (
  shipmentUUID?: string,
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const { data } = await httpClient.get(
    `/shipments/${shipmentUUID}/ratecon.pdf`,
    {
      ...config,
      responseType: 'blob',
    }
  )
  return data
}

export const SHIPMENT_CHARGE_TYPES_URL = '/charges/charge-types'
export const getShipmentChargeTypes = async (
  mode: TransportationModeCode,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `${SHIPMENT_CHARGE_TYPES_URL}?modes=${mode}`,
    config
  )

  return shipmentChargeTypesAdapter(data)
}

export const getShipmentInvoices = async (
  shipmentUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/shipments/${shipmentUUID}/invoices`,
    config
  )
  return data
}

export const requestCarrierInvoice = async (
  shipmentUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/shipments/${shipmentUUID}/request-carrier-for-invoice`,
    config
  )
  return data
}

export const updateAppointment = async ({
  shipmentUUID,
  stopIndex,
  payload,
}: {
  shipmentUUID: string
  stopIndex: number
  payload: UpdateAppointmentPayload
}) => {
  const { data } = await httpClient.put(
    `/shipments/${shipmentUUID}/stops/${stopIndex}/appointment`,
    payload
  )
  return data
}

export const deleteAppointment = async ({
  shipmentUUID,
  stopIndex,
}: {
  shipmentUUID: string
  stopIndex: number
}) => {
  const { data } = await httpClient.delete(
    `/shipments/${shipmentUUID}/stops/${stopIndex}/appointment`
  )
  return data
}

export const getShipmentTimeline = async (
  shipmentUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<{
    entries: ShipmentTimelineEntryRaw[]
  }>(`/shipments/${shipmentUUID}/timeline`, config)

  return data
}
