import { calculateTotalDensity } from 'fulfillments/domain/Fulfillment'
import type {
  FulfillmentPayload,
  FulfillmentWithoutOrderPayload,
  TransientFulfillment,
} from 'fulfillments/domain/Fulfillment'
import { defaultTo, get, omit } from 'lodash'

import type {
  TransientCommodity,
  TransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  calculateTotalVolume,
  calculateTotalWeight,
} from 'components/ShippingItemsManager'
import { formatISODate } from 'utils/dateUtils'

export function adaptCommoditiesWithoutOrders(
  commodities?: TransientCommodity[]
) {
  if (!commodities) {
    return []
  }

  return commodities.map((commodity) => {
    const baseCommodity = omit(commodity, ['_metadata', 'package_count'])

    return {
      ...baseCommodity,
      shipped_count: commodity.package_count,
    }
  })
}

export function adaptHandlingUnitsWithoutOrders(
  handlingUnits?: TransientHandlingUnit[]
) {
  if (!handlingUnits) {
    return []
  }

  return handlingUnits.map((handlingUnit) => {
    const { commodities, height, length, width, ...baseHandlingUnit } = omit(
      handlingUnit,
      ['_metadata', 'order_items', 'pickup_stop_index', 'weight_type'] as const
    )

    const totalWeight = calculateTotalWeight(commodities)
    const totalVolume = calculateTotalVolume(commodities)
    const totalDensity = calculateTotalDensity(totalWeight, totalVolume)

    const adaptedHandlingUnit = {
      ...baseHandlingUnit,
      commodities: adaptCommoditiesWithoutOrders(commodities),
      package_height: height,
      package_length: length,
      package_width: width,
      total_density: totalDensity,
      total_volume: totalVolume,
      total_weight: totalWeight,
    }

    return adaptedHandlingUnit
  })
}

export function adaptCommoditiesWithOrders(commodities?: TransientCommodity[]) {
  if (!commodities) {
    return []
  }

  return commodities.map((commodity) => {
    return {
      order_item_uuid: get(commodity, 'order_item_uuid', ''),
      shipped_weight: get(commodity, 'weight', ''),
      shipped_count: get(commodity, 'package_count', ''),
    }
  })
}

export function adaptHandlingUnitsWithOrders(
  handlingUnits?: TransientHandlingUnit[]
): FulfillmentPayload['handling_units'] {
  if (!handlingUnits) {
    return []
  }

  return handlingUnits.map((handlingUnit) => {
    const { commodities, height, length, width, ...baseHandlingUnit } = omit(
      handlingUnit,
      ['_metadata', 'order_items', 'pickup_stop_index', 'weight_type'] as const
    )

    const totalWeight = calculateTotalWeight(commodities)
    const totalVolume = calculateTotalVolume(commodities)
    const totalDensity = calculateTotalDensity(totalWeight, totalVolume)

    const adaptedHandlingUnit = {
      ...baseHandlingUnit,
      commodities: adaptCommoditiesWithOrders(commodities),
      package_height: height,
      package_length: length,
      package_width: width,
      total_density: totalDensity,
      total_volume: totalVolume,
      total_weight: totalWeight,
    }

    return adaptedHandlingUnit
  })
}

export function createFulfillmentAdapter(
  fulfillment: TransientFulfillment
): FulfillmentPayload {
  const payload = omit(fulfillment, [
    '_metadata',
    'owner',
    'input_method',
    'po_numbers',
    'so_numbers',
    'stops',
    'handling_units',
  ] as const)

  const [pickup, delivery] = get(fulfillment, 'stops', [])

  return {
    ...payload,
    pickup_facility_uuid: defaultTo(pickup.facility?.uuid, ''),
    pickup_ready_date: pickup.date ? formatISODate(pickup.date) : '',
    pickup_contact_uuid: defaultTo(pickup.contact?.uuid, null),
    pickup_instructions: defaultTo(pickup.notes, ''),

    delivery_facility_uuid: defaultTo(delivery.facility?.uuid, ''),
    delivery_date: delivery.date ? formatISODate(delivery.date) : '',
    delivery_contact_uuid: defaultTo(delivery.contact?.uuid, null),
    delivery_instructions: defaultTo(delivery.notes, ''),
    handling_units: adaptHandlingUnitsWithOrders(fulfillment.handling_units),
  }
}

export function createFulfillmentWithoutOrdersAdapter(
  fulfillment: TransientFulfillment
): FulfillmentWithoutOrderPayload {
  const payload = omit<TransientFulfillment>(fulfillment, [
    '_metadata',
    'owner',
    'input_method',
    'po_numbers',
    'so_numbers',
    'stops',
    'handling_units',
  ])

  const [pickup, delivery] = get(fulfillment, 'stops', [])

  return {
    ...payload,
    pickup_facility_uuid: defaultTo(pickup.facility?.uuid, ''),
    pickup_ready_date: pickup.date ? formatISODate(pickup.date) : '',
    pickup_contact_uuid: defaultTo(pickup.contact?.uuid, null),
    pickup_instructions: defaultTo(pickup.notes, ''),

    delivery_facility_uuid: defaultTo(delivery.facility?.uuid, ''),
    delivery_date: delivery.date ? formatISODate(delivery.date) : '',
    delivery_contact_uuid: defaultTo(delivery.contact?.uuid, null),
    delivery_instructions: defaultTo(delivery.notes, ''),
    handling_units: adaptHandlingUnitsWithoutOrders(fulfillment.handling_units),
  }
}
