import type { AxiosRequestConfig } from 'axios'

import type {
  Quote,
  UpdateQuoteBody,
  UpdateQuoteItemBody,
} from 'screens/Quotes/types'
import httpClient from 'utils/httpClient'
import { createSearchParams } from 'utils/searchParams'

type ListQuotesParams = {
  limit: number
  offset: number
  sort: string | null
  search_term: string | null
  status: string[]
  mode: string[]
  equipment_type: string[]
  pickup_date_after: string | null
  pickup_date_before: string | null
  pickup_location: string | null
  delivery_location: string | null
}

export const listQuotes = async (
  params?: Partial<ListQuotesParams>,
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  const { data } = await httpClient.get('/spot_quotes', {
    ...config,
    params: createSearchParams(params),
  })
  return data
}

export const getQuote = async (
  quoteUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<Quote>(`/quotes/${quoteUUID}`, config)
  return data
}

export const deleteQuote = async ({
  quoteUUID,
}: {
  quoteUUID: string | undefined
}) => {
  const { data } = await httpClient.delete(`/quotes/${quoteUUID}`)
  return data
}

export const bulkDeleteDryRunQuote = async ({
  quoteUUIDs,
}: {
  quoteUUIDs: string[] | undefined
}) => {
  const { data } = await httpClient.post(`/spot_quotes/bulk-delete/dry-run`, {
    spot_quotes: quoteUUIDs,
  })
  return data
}

export const bulkDeleteQuote = async ({
  quoteUUIDs,
}: {
  quoteUUIDs: string[] | undefined
}) => {
  const { data } = await httpClient.post(`/spot_quotes/bulk-delete`, {
    spot_quotes: quoteUUIDs,
  })
  return data
}

// The endpoint is only used to update notes for now.
// If you intend to use it for other things, just make sure that the endpoint supports it.
// And then update the type UpdateQuoteBody
export const updateQuote = async (quoteUUID: string, body: UpdateQuoteBody) => {
  const { data } = await httpClient.patch(`/quotes/${quoteUUID}`, body)
  return data
}

export const updateQuoteItem = async (
  quoteUUID: string,
  itemUUID: string,
  body: UpdateQuoteItemBody
) => {
  const { data } = await httpClient.patch(
    `/quotes/${quoteUUID}/items/${itemUUID}`,
    body
  )
  return data
}

export const removeFile = async ({
  quoteUUID,
  fileID,
}: {
  quoteUUID: string
  fileID: string
}) => {
  const { data } = await httpClient.delete(
    `spot_quotes/${quoteUUID}/attachments/${fileID}`
  )
  return data
}

export const uploadFile = async (file: File) => {
  const formData = new FormData()

  formData.append('input_file', file)

  const { data } = await httpClient.post(`/bulk/upload-quotes`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export const beginImportedQuotesCreation = async (
  bulkQuotesUploadUUID: string
) => {
  const { data } = await httpClient.patch(
    `/bulk/upload-quotes/${bulkQuotesUploadUUID}/trigger-quotes-creation`
  )
  return data
}

export const getQuotesUploadProgress = async (
  bulkQuotesUploadUUID: string | undefined,
  config?: AxiosRequestConfig
) => {
  if (bulkQuotesUploadUUID) {
    const { data } = await httpClient.get(
      `/bulk/upload-quotes/${bulkQuotesUploadUUID}`,
      config
    )
    return data
  }
}

export const getQuotesUploadErrorReport = async (
  bulkUploadUUID: string,
  config?: Omit<AxiosRequestConfig, 'repomseType'>
) => {
  const { data } = await httpClient.get(
    `/bulk/upload-quotes/${bulkUploadUUID}/error-report`,
    {
      ...config,
      responseType: 'blob',
    }
  )
  return data
}

export const bulkInvite = async (payload: { quotes: string[] }) => {
  const { data } = await httpClient.post(`/bulk/bulk-invite`, payload)
  return data
}
