import { get, set } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useMultipleFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import type { TransientStop } from 'components/StopsManager'
import { usePlannableOrdersOptions } from 'orders/hooks/usePlannableOrdersOptions'
import type { ListOrder } from 'orders/types'
import type { FacilityDetailsV2 } from 'services/facilities'
import { formatDateUTC } from 'utils/dateUtils'
import { hasTransientError } from 'utils/transient'

import type { TransientFulfillment } from '../domain/Fulfillment'
import { createTransientFulfillment } from '../domain/Fulfillment'
import { validate } from '../domain/Fulfillment.validation'

export function mapLaneFromOrder(
  order: ListOrder,
  facilities: (FacilityDetailsV2 | undefined)[],
  stops?: TransientStop[]
) {
  if (stops && stops.length >= 2) {
    const pickupDate = order.pickup_window_start
      ? formatDateUTC(order.pickup_window_start, 'MM/DD/YYYY')
      : stops[0].date
    const deliveryDate = order.delivery_window_start
      ? formatDateUTC(order.delivery_window_start, 'MM/DD/YYYY')
      : stops[1].date
    const pickupFacility = facilities.find(
      (facility) => facility?.uuid === order.pickup_facility_uuid
    )
    const deliveryFacility = facilities.find(
      (facility) => facility?.uuid === order.delivery_facility_uuid
    )

    return [
      {
        ...stops[0],
        date: pickupDate,
        facility: pickupFacility,
      },
      {
        ...stops[1],
        date: deliveryDate,
        facility: deliveryFacility,
      },
    ]
  }

  return stops
}

export type FulfillmentFormContextValue = {
  fulfillment: TransientFulfillment
  setPartialFulfillment: (value: Partial<TransientFulfillment>) => void
  setFulfillment: Dispatch<SetStateAction<TransientFulfillment>>
  isLoadingOrders?: boolean
  selectedOrder?: ListOrder | null
  ordersOptions: {
    label: string
    value: string
  }[]
}

export const FulfillmentFormContext = createContext<
  FulfillmentFormContextValue | undefined
>(undefined)

export function useFulfillmentFormContext() {
  const context = useContext(FulfillmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useFulfillmentFormContext must be used within a FulfillmentFormContext.Provider'
    )
  }

  return context
}

export function FulfillmentFormProvider({
  children,
}: {
  readonly children: React.ReactNode
}) {
  const [fulfillment, setFulfillment] = useState<TransientFulfillment>(
    createTransientFulfillment()
  )

  const setPartialFulfillment = useCallback(
    (partialState: Partial<TransientFulfillment>) => {
      setFulfillment((currentFulfillment) => {
        const updatedFulfillment = Object.keys(partialState).reduce(
          (newFulfillment, path) => {
            return set(newFulfillment, path, get(partialState, path))
          },
          { ...currentFulfillment }
        )

        if (hasTransientError(updatedFulfillment)) {
          const [validatedFulfillment] = validate(updatedFulfillment)
          return validatedFulfillment
        }

        return updatedFulfillment
      })
    },
    [setFulfillment]
  )

  const {
    orders,
    ordersOptions,
    isLoading: isLoadingOrders,
  } = usePlannableOrdersOptions()

  const selectedOrder = useMemo(() => {
    if (fulfillment.order_uuid) {
      return orders.find((order) => order.uuid == fulfillment.order_uuid)
    }

    return null
  }, [fulfillment.order_uuid, orders])

  const value = useMemo(() => {
    return {
      fulfillment,
      setFulfillment,
      setPartialFulfillment,
      isLoadingOrders,
      selectedOrder,
      ordersOptions,
    }
  }, [
    fulfillment,
    setPartialFulfillment,
    isLoadingOrders,
    ordersOptions,
    selectedOrder,
  ])

  // pre-setting fields based on selected order
  const orderFacilitiesUUIDs = selectedOrder
    ? [selectedOrder.pickup_facility_uuid, selectedOrder.delivery_facility_uuid]
    : []

  const facilities = useMultipleFacilityDetailsV2(orderFacilitiesUUIDs, {
    enabled: orderFacilitiesUUIDs.length > 0,
  })

  useEffect(() => {
    if (selectedOrder && facilities) {
      setPartialFulfillment({
        stops: mapLaneFromOrder(
          selectedOrder,
          facilities.facilities,
          fulfillment.stops
        ),
      })
    }
  }, [facilities, selectedOrder, setPartialFulfillment])

  return (
    <FulfillmentFormContext.Provider value={value}>
      {children}
    </FulfillmentFormContext.Provider>
  )
}
