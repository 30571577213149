import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { useSettings } from 'contexts/settings'

import ViewOrderPage from './details/ViewOrderPage'
import ListOrdersPage from './list/OrdersListPage'

export function OrdersRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match

  const {
    isLoading,
    values: [ordersPageEnabled],
  } = useSettings(['flags.ENABLE_ORDERS_PAGE'])

  if (isLoading || !ordersPageEnabled) {
    return <GlobalSpinner />
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact component={ListOrdersPage} />
      <Route path={`${path}/:id`} exact component={ViewOrderPage} />
    </Switch>
  )
}
