import { Header, PageContent } from '@loadsmart/miranda-react'
import { Helmet } from 'react-helmet'

import { NewShipmentFromOrdersButton } from '../../screens/Orders/ManualConsolidation'
import { OrdersList } from './OrdersList'

export default function OrdersListPage() {
  return (
    <>
      <Helmet>
        <title>Orders | ShipperGuide</title>
      </Helmet>
      <PageContent>
        <Header>
          <Header.Content>
            <Header.Title data-testid="title">Orders</Header.Title>
          </Header.Content>
          <Header.Actions>
            <NewShipmentFromOrdersButton />
          </Header.Actions>
        </Header>
        <PageContent.Body>
          <OrdersList />
        </PageContent.Body>
      </PageContent>
    </>
  )
}
